import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, RelatedPagesLink, PanelLabel, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp } from "react-icons/md";

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { AdPanel, SidebarPanel, SidebarGrid } from '../index'
import EmailOptin from '../../components/EmailOptin'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import GoogleAdWrapper from '../../components/GoogleAd'
import { GumroadAd } from '../../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CoastFireCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorGrid = styled(ContainerMain)`
  background-color: ${props => props.theme.theme.bg.primary};
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

// const GraphDesktop = styled.div`
//   display: block;

//   @media (max-width: 600px) {
//     display: none;
//   }
// `

// const GraphMobile = styled.div`
//   display: none;

//   @media (max-width: 600px) {
//     display: block;
//   }

//   .recharts-text, .recharts-cartesian-axis-tick-value {
//     fill: ${props => props.theme.theme.text.primary} !important;
//   }
// `

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const Countdown = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Age: </strong>
          {payload[0].payload.age}</CardText400>
        <CardText400>
          <strong>Net Worth: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.actualNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Coast FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.theoreticalNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.fireNumber.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CoastFireCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ currentAge, setCurrentAge ] = useState(30)
  const [ retirementAge, setRetirementAge ] = useState(67)
  const [ annualSpend, setAnnualSpend ] = useState(30000)
  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ inflationRate, setInflationRate ] = useState(3)
  const [ currentNetWorth, setCurrentNetWorth ] = useState(100000)
  const [ SWR, setSWR ] = useState(4)
  const [ payment, setPayment ] = useState(500)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(1)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)

  const rate = (Math.pow( 1 + (( ( investmentRate - inflationRate ) /100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1


  const coastNumber = ( annualSpend / (.01*SWR) ) / Math.pow( (1 + (.01*(investmentRate - inflationRate))), (retirementAge - currentAge))
  const fireNumber = annualSpend / (.01*SWR)

  let coastFireCountdown = 0

  const calcNetWorthData = () => {
    let netWorthData = []

    for(let i = currentAge; i <= retirementAge; i++){
      console.log('test')

      const time = i - currentAge
      const localNper = annualPaymentPeriods * time
      const actualNetWorth = currentNetWorth * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )
      const continuousGrowthNetWorth = currentNetWorth * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )
      const theoreticalNetWorth = coastNumber * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge))

      if (!coastFireCountdown && actualNetWorth >= theoreticalNetWorth) {
        coastFireCountdown = time

        if(time == 0){
          coastFireCountdown = "You've already hit coast FIRE!"
        }
      }

      if (i == retirementAge && actualNetWorth < theoreticalNetWorth ){
        coastFireCountdown = `You need to increase your monthly contribution to reach Coast FIRE before age ${retirementAge}`
      }

      if(actualNetWorth >= theoreticalNetWorth){

        if(coastFireCountdown == "You've already hit coast FIRE!"){
          const actualNetWorthNoPayments = currentNetWorth * Math.pow( (1 + (.01*(investmentRate - inflationRate)) ), (i - currentAge))

          netWorthData.push({
            age: i,
            actualNetWorth: actualNetWorthNoPayments,
            theoreticalNetWorth: theoreticalNetWorth,
            fireNumber: fireNumber,
            continuousGrowthNetWorth: continuousGrowthNetWorth
          })
        } else {
          netWorthData.push({
            age: i,
            actualNetWorth: theoreticalNetWorth,
            theoreticalNetWorth: theoreticalNetWorth,
            fireNumber: fireNumber,
            continuousGrowthNetWorth: continuousGrowthNetWorth
          })
        }
      } else{
        netWorthData.push({
          age: i,
          actualNetWorth: actualNetWorth,
          theoreticalNetWorth: theoreticalNetWorth,
          fireNumber: fireNumber,
          continuousGrowthNetWorth: continuousGrowthNetWorth
        })
      }
    }
    return netWorthData
  }

  const netWorthData = calcNetWorthData()

  console.log(netWorthData)

  return (
    <Layout>
      <SEO
        title={'Coast FIRE Calculator - Coasting to FI'}
        description={'Visualize and calculate how much you need to "coast" to Financial Independence'}
        keywords={site.keywords}
      />
      <ReactTooltip />

      <ContainerMain>
        <ContainerContent>
          <h1>Coast FIRE Calculator - Coasting to FI</h1>
          <H2>Visualize and calculate how much you need to "coast" to Financial Independence</H2>
          <p>
            Coast FIRE is when you have enough in your retirement accounts that without any additional contributions,
            your net worth will grow to support retirement at a traditional retirement age.
          </p>
          <p>
            Once your net worth has passed the Coast FIRE milestone, you still need to earn enough to cover your monthly cost of living, but you no longer need to save money for retirement.
            If you save up and invest enough money early enough in life, you can allow your existing investments to compound over time and grow to be enough to retire on at a traditional retirement age.
            This strategy of "Coasting to FIRE" gives you the freedom to pursue a different job that pays less, shift to working part-time, or just have more spending money to enjoy life.
          </p>
          <p>
            To learn more about the details and math behind Coast FIRE, checkout my
            post: <Link to='/coast-fire'>What is Coast FIRE: The Complete Guide to Coasting to Financial Independence</Link>.
          </p>
        </ContainerContent>
      </ContainerMain>

      <ContainerMain>
        <GoogleAdWrapper /> 
      </ContainerMain>

        <CalculatorGrid>

        <form>
          <ThemedTextarea
            id="outlined-name"
            label="Current Age"
            value={currentAge}
            onChange={event => setCurrentAge(event.target.value)}
            variant="outlined"
            fullWidth
          />
          <ThemedTextarea
            id="outlined-name"
            label="Retirement Age"
            value={retirementAge}
            onChange={event => setRetirementAge(event.target.value)}
            variant="outlined"
            fullWidth
          />
          <ThemedTextarea
            id="outlined-name"
            label="Annual Spending in Retirement"
            value={annualSpend}
            onChange={event => setAnnualSpend(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <ThemedTextarea
            id="outlined-name"
            label="Current Invested Assets"
            value={currentNetWorth}
            onChange={event => setCurrentNetWorth(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <ThemedTextarea
            id="outlined-name"
            label="Monthly contribution"
            value={payment}
            onChange={event => setPayment(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />


          <Label>
            Investment growth rate
            <MdHelp size="1.4rem"
                data-tip={`This is your investment's overall (nominal) rate of return before adjusting for inflation.
                Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.`}
                />
          </Label>

          <SliderGrid>
            <ThemedSlider
              value={investmentRate}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={4}
              max={14}
              marks={[
                {
                  value: 4,
                  label: '4%'
                },
                {
                  value: 7,
                  label: '7%'
                },
                {
                  value: 14,
                  label: '14%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setInvestmentRate(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="Growth"
              value={investmentRate}
              onChange={event => setInvestmentRate(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

          <Label>
            Inflation rate
            <MdHelp size="1.4rem"
                data-tip={`This is the average annual rate of inflation that you expect to experience in the future.
                Historically, the US economy has experienced an annual average inflation rate of 3%.
                This value is used to normalize the calculator to be in 2020 dollars.`}
            />
          </Label>

          <SliderGrid>

            <ThemedSlider
              value={inflationRate}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={2}
              max={6}
              marks={[
                {
                  value: 2,
                  label: '2%'
                },
                {
                  value: 3,
                  label: '3%'
                },
                {
                  value: 6,
                  label: '6%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setInflationRate(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="Inflation"
              value={inflationRate}
              onChange={event => setInflationRate(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

          <Label>
            Safe Withdrawal Rate
            <MdHelp size="1.4rem"
              data-tip="Safe withdrawal rate (SWR) is the percentage of your net worth that you withdraw each year in retirement.
                 4% is widely considered as the recommended SWR for retirement planning."
                />
          </Label>

          <SliderGrid>
            <ThemedSlider
              value={SWR}
              aria-labelledby="discrete-slider-always"
              step={0.25}
              min={2}
              max={6}
              marks={[
                {
                  value: 2,
                  label: '2%'
                },
                {
                  value: 4,
                  label: '4%'
                },
                {
                  value: 6,
                  label: '6%'
                }
              ]}
              valueLabelDisplay="auto"
              onChange={ (event, value) => setSWR(value)}
            />

            <ThemedTextarea
              id="outlined-name"
              label="SWR"
              value={SWR}
              onChange={event => setSWR(event.target.value)}
              variant="outlined"
              InputProps={{
                inputComponent: NumberFormatPercentage,
              }}
              fullWidth
            />
          </SliderGrid>

            <ThemedTextareaPrimary
              id="outlined-read-only-input"
              label="Coast FIRE Number at Current Age"
              value={coastNumber.toFixed(0)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true
              }}
            />

          <Countdown>
            {isNaN(coastFireCountdown) ?
              coastFireCountdown :
              `You're ${coastFireCountdown} years from Coast FIRE!`}
          </Countdown>


        </form>

        <GraphDesktop>
          <ResponsiveContainer width='100%' height={700} >
            <AreaChart data={netWorthData}
                margin={{top: 10, right: 0, left: 50, bottom: 30}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="age" label={{value: "Age (years)", position: "insideBottom", offset: -10}} />
              <YAxis
                tickFormatter={tick => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                }}
              />
              {/* <Tooltip/> */}
              <RechartsTooltip
                content={<CustomTooltip/>}
              />
              <Legend verticalAlign="top" height={60} />
              <Area type='monotone' name="Net worth with continued contributions" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} />
              <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' />
              <Area type='monotone' name="Net worth with no contributions after Coast FIRE milestone" dataKey='actualNetWorth' stroke='#3AC732' fill='#CFF0CD' />
              <Area type='monotone' name="FIRE number" dataKey='fireNumber' stroke='#FF1733' fillOpacity={0} />
            </AreaChart>
          </ResponsiveContainer>
        </GraphDesktop>

        <GraphMobile>
          <ResponsiveContainer width='100%' height={700} >
            <AreaChart data={netWorthData}
                margin={{top: 10, right: 0, left: 10, bottom: 30}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="age" label={{value: "Age (years)", position: "insideBottom", offset: -10}} />
              <YAxis
                tickFormatter={tick => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                }}
                mirror
              />
              {/* <Tooltip/> */}
              <RechartsTooltip
                content={<CustomTooltip/>}
              />
              <Legend verticalAlign="top" height={170} />
              <Area type='monotone' name="Net worth with continued contributions" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} />
              <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' />
              <Area type='monotone' name="Net worth with no contributions after Coast FIRE milestone" dataKey='actualNetWorth' stroke='#3AC732' fill='#CFF0CD' />
              <Area type='monotone' name="FIRE number" dataKey='fireNumber' stroke='#FF1733' fillOpacity={0} />
            </AreaChart>
          </ResponsiveContainer>
        </GraphMobile>


      </CalculatorGrid>

      <GumroadAd />

      <RelatedPagesPanel style={{marginTop: '24px'}}>
        <PanelLabel>Related Pages</PanelLabel>
        <RelatedPagesGroup>
          <Link to='/coast-fire'>Coast FIRE Complete Guide</Link>
          <Link to='/coast-fire-grid'>Coast FIRE Grid</Link>
          <Link to='/tools/fire-calculator'>FIRE Calculator</Link>
          <Link to='/tools/barista-fire-calc'>Barista FIRE Calculator</Link>
          <Link to='/coast-fire-jobs'>Best Jobs for Coast FIRE</Link>
        </RelatedPagesGroup>
      </RelatedPagesPanel>

      <ContainerMain>
        <GoogleAdWrapper /> 
      </ContainerMain>

      <ContainerTwoCol>


      <ContainerContent>

        <h2>Using this calculator</h2>
        <p>
          This interactive calculator makes it easy to calculate and visualize the growth of your current investments as you plan your journey of Coasting to Financial Independence.
        </p>

        <ul>
          <li>On the left, start by entering your current age and the age you plan to retire.</li>
          <li>
            Then enter the amount you plan to spend annually in retirement.
            Note that in many cases, this number will be less your current spending, because
            (1) you will be covered by Medicare and (2) you may have paid off your primary residence, so you no longer have to pay a mortgage.
          </li>
          <li>
            In the Current Invested Assets box, enter the amount that you currently have invested.
            For example, if you have $100,000 invested in the stock market (perhaps through your employer's 401k) and $25,000 saved in your emergency fund, then you should enter $100,000 in this field.
          </li>
          <li>
            Use the sliders to adjust the rates and watch the graph to the right immediately react to your change!
          </li>
          <li>
            <strong>Investment rate of return </strong> is the average return that you expect your investments to grow, not adjusted for inflation.
            This calculator uses 7% as a default Investment rate of return, which is a relatively conservative assumption.
            Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.
          </li>
          <li>
            <strong>Inflation rate </strong> is the average annual rate of inflation that you expect to experience in the future.
            Historically, the US economy has experienced an annual average inflation rate of 3%.
          </li>
          <li>
            <strong>Safe Withdrawal Rate </strong> (SWR) is the estimated percentage of your net worth that you expect to withdraw to cover your living expenses in retirement.
            4% is widely considered as the recommended SWR for retirement planning.
            This 4% withdrawal rate was found by <a href="https://www.bogleheads.org/wiki/Trinity_study_update" target="_blank"> the Trinity Study</a> to have a 100% success rate over a 30-year retirement horizon with a 50% / 50% mix of stocks and bonds.
          </li>
        </ul>

        <p>
          The green line on the graph represents the growth of your current net worth over time with the monthly contribution that you input.
          The blue line on the graph shows the amount at each age you need to have saved and invested to reach the Coast FIRE milestone.
          You can see how these curves shift relative to each other as you adjust the sliders. If your net worth is greater than your Coast FIRE number, then you have reached Coast FIRE!
        </p>

        <h2>The math behind the calculator</h2>

        <p>
          First, let's recall how to calculate your regular FIRE number:
        </p>
        <p>
          <em>(FIRE number) = (annual spending) / SWR</em>
        </p>
        <p>
          This calculator uses the compound interest formula:
        </p>

        <p>
          <em>A = P * ( 1 + n)^t</em>
        </p>

        <p>
          Here A is the final amount, P is the principle (initial amount), n is the annual growth rate, and t is the time in years.
          We can set our final amount A to be equal to our <em>FIRE number</em> and then solve for P, the initial amount, which is our <em>Coast FIRE number</em>. This gives us:
        </p>
        <p>
          <em>(Coast FIRE number) = (annual spending) / ( SWR * (1 + n)^t )</em>
        </p>

        <h2>But what about inflation?</h2>
        <p>
          Inflation is an important variable to account for when planning for retirement decades in the future.
          It is almost certain that we will experience inflation in the future, and for this reason having your money <u>invested in assets</u> and <u>not</u> all stuffed under your mattress is crucially important.
          Assets like stocks and real estate tend to rise with inflation while cash loses value, meaning the best way to preserve your wealth in times of high inflation is to be invested in these assets.
        </p>

        <p>
          <strong>Don't worry about inflation, it's built-in to the calculator! </strong>
          This calculator accounts for inflation by subtracting the inflation rate (from the input slider) from the investment growth rate of return. This gives an <em>inflation-adjusted </em>
          rate of return which is then used to calculate your Coast FIRE number and draw the graph. With this approach, all the numbers in the calculator are adjusted to be in today's dollars.
          Think about it like this - you don't have to worry about cost-of-living increases because it's already skimmed off of your expected investment returns.
        </p>

        <RelatedPagesPanel style={{marginTop: '24px'}}>
          <PanelLabel>Support WalletBurst</PanelLabel>
          <p>
            Enjoying using my Coast FIRE calculator? You can support me at no cost to you by shopping with
            my <RelatedPagesLink href="https://amzn.to/3xKWm0R" target="_blank">Amazon link</RelatedPagesLink>. Thanks!
          </p>
      </RelatedPagesPanel>
      </ContainerContent>

      <Sidebar />

      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'coast-fire-calc'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default CoastFireCalc
